
import { Component } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Mutation, Getter } from "vuex-class";
import { ISupplier } from "@/types/supplier";
import SupplierStaffList from "@/components/admin/list/SupplierStaffList.vue";

@Component({
  components: { SupplierStaffList }
})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  @Action("supplier/adminGet")
  public getSupplier!: (id: number) => void;

  @Getter("supplier/single")
  public supplier!: ISupplier;

  @Mutation("supplier/clear")
  public clear!: () => void;

  // fields
  public supplier_id = 0;
  public items: { text: string; icon: string; to: string }[] = [];

  public async created() {
    this.supplier_id = Number(this.$route.params.supplier_id);

    this.items = [
      {
        text: "詳細",
        icon: "mdi-card-account-details-outline",
        to: `/admin/supplier/${this.supplier_id}`
      },
      {
        text: "サプライヤ担当者",
        icon: "mdi-account",
        to: `/admin/supplier/${this.supplier_id}/supplier_staff`
      }
    ];

    this.clear();
    await Promise.all([this.getSupplier(this.supplier_id)]);
  }
}
